<template>
	<div class="add_update_role">
		<jy-dialog
			type="table"
			:title="title"
			:visible.sync="dialogVisible"
			custom-class="dialog_table"
			:width="global.dialogTabelWidth"
			append-to-body
		>
			<div>
				<div class="top_value_box">
					<el-row>
						<el-col :span="12">
							<el-form :model="form" label-width="80px">
								<el-form-item label="所选车辆">
									<el-select v-model="selectedIdsList" multiple placeholder @remove-tag="removeTag" class="select_view">
										<el-option
											disabled
											v-for="item in selectedList"
											:key="item.vehicleId"
											:label="item.vehicleNo"
											:value="item.vehicleId"
											class="select_rote_add_update_li"
										>
											<span class="box_span">
												<span v-text="item.vehicleNo || ''"></span>
											</span>
										</el-option>
									</el-select>
								</el-form-item>
							</el-form>
						</el-col>
					</el-row>
				</div>
				<jy-query ref="form" :model="form" label-width="80px">
					<jy-query-item prop="vehicleNo" label="车牌号:" :span="1.5">
						<el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
					</jy-query-item>
					<template slot="search">
						<el-button type="primary" @click="oncheck">查询</el-button>
						<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
					</template>
				</jy-query>
				<!-- @select-all="selectallchange"@select="selectchange"-->
				<jy-table max-height="595" ref="multipleTable" :data="dataList" v-loading="loading" @selection-change="selectchange">
					<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
					<jy-table-column type="selection" width="40"></jy-table-column>
					<jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
					<jy-table-column prop="vin" label="VIN"></jy-table-column>
					<!-- <jy-table-column prop="routeName" label="关联线路"></jy-table-column> -->
					<jy-table-column prop="brand" label="品牌"></jy-table-column>
				</jy-table>
				<jy-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageIndex"
					:page-size="pageSize"
					:total="total"
				></jy-pagination>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancelFun">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</jy-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			type: "",
			isCheck: false,
			title: "未绑定车辆选择",
			form: {
				vehicleNo: "",
				vehicleId: ""
			},
			routeId: "",
			orgId: "",
			dialogVisible: false,
			formLabelWidth: "100px",
			tableSeleList: [],
			selectedList: [],
			selectedIdsList: [],
			pageSize: 10,
			total: 0,
			pageIndex: 1,
			dataList: [],
			loading: true
		};
	},
	props: {
		listUrl: {
			type: String,
			default: "/routeRelVehicle/selectAllNoBindVehicle"
		}
	},
	activated() {},
	created() {
		// this.getList()
	},
	watch: {
		dialogVisible(newview) {
			if (!newview) {
				this.resetForm("form");
				this.routeId = "";
				this.orgId = "";
			}
		},
		tableSeleList: function () {
			//将此操作放到最后所行
			setTimeout(() => {
				this.setSelectedList(this.tableSeleList, 0);
			});
		},
		selectedList: function () {
			this.setSelectedIdsList();
		}
	},
	methods: {
		init(selectedIdsList, selectedList, orgId, routeId) {
			this.selectedList = selectedList;
			this.selectedIdsList = selectedIdsList;
			this.orgId = orgId;
			this.routeId = routeId;
			this.dialogVisible = true;
			this.getList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		cancel() {
			this.dialogVisible = false;
			console.log("cancel");
		},
		getList() {
			let option = {
				...this.form,
				routeId: this.routeId,
				orgId: this.orgId,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			console.log(option);
			let url = this.listUrl;
			this.$http.post(url, option).then(
				({ detail }) => {
					// console.log(detail)
					this.dataList = detail.list;
					this.total = detail.total;
					this.loading = false;
					this.toggleRow();
				},
				() => {
					this.loading = false;
				}
			);
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 勾选数据
		selectchange(selection) {
			//   console.log(selection)
			this.tableSeleList = selection;
		},
		selectallchange(selection) {
			//   console.log(selection)
			this.tableSeleList = selection;
		},
		cancelFun() {
			this.dialogVisible = false;
		},
		save() {
			this.$emit("updateSite", this.selectedIdsList, this.selectedList);
			this.dialogVisible = false;
		},
		removeTag(item) {
			this.setSelectedList(this.selectedIdsList, 2);
			this.removeTableSeleItem(item);
		},
		removeTableSeleItem(one) {
			this.tableSeleList.forEach(item => {
				if (item.vehicleId == one) {
					this.$refs.multipleTable.toggleRowSelection(item);
				}
			});
		},
		toggleRow() {
			this.$nextTick(() => {
				this.dataList.forEach(one => {
					this.selectedList.forEach(item => {
						var inTableSeleList = this.tableSeleList.some(oldOne => {
							return one.vehicleId == oldOne.vehicleId;
						});
						if (one.vehicleId == item.vehicleId && !inTableSeleList) {
							one.order = item.order;
							item = one;
							this.$refs.multipleTable.toggleRowSelection(one);
						}
					});
				});
			});
		},
		setSelectedList(list, type) {
			var oldList = this.selectedList;
			var l = [];
			if (type == 0) {
				oldList.forEach(item => {
					var inAll = false,
						inSele = false;
					inAll = this.dataList.some(itemAll => {
						return itemAll.vehicleId == item.vehicleId;
					});
					inSele = list.some(itemSele => {
						return itemSele.vehicleId == item.vehicleId;
					});
					if (!inAll || inSele) {
						l.push(item);
					}
				});
				list.forEach(itemSele => {
					var inOld = oldList.some(itemOld => {
						return itemOld.vehicleId == itemSele.vehicleId;
					});
					if (!inOld) {
						l.push(itemSele);
					}
				});
			} else if (type == 1) {
				console.log("1");
			} else if (type == 2) {
				oldList.forEach(item => {
					var inIds = false;
					inIds = list.some(one => {
						return one == item.vehicleId;
					});
					if (inIds) {
						l.push(item);
					}
				});
			}
			l.forEach((one, k) => {
				one.order = k + 1;
			});
			this.selectedList = l;
		},
		setSelectedIdsList() {
			this.selectedIdsList = this.selectedList.map(item => {
				return item.vehicleId;
			});
		},
		// 确定选择
		selectSure() {
			if (this.batchdeleteList.length === 0) {
				this.$message({
					message: "请选择车牌",
					type: "warning"
				});
			} else {
				this.dialogVisible = false;
				this.$emit("updateSite", this.batchdeleteList);
			}
		}
	}
};
</script>
<style type="text/css" lang="scss" scoped="linecar_select">
.select_view {
	width: 100%;
}
</style>
