<template>
    <div class="add_update_role">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :disabled="isCheck" :label-width="formLabelWidth">
                    <el-form-item label="线路组织:" prop="routeName">
                        <el-input placeholder="请选择" @focus="selectInstitutions" v-model="diaform.routeName" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="车牌号:" prop="ids">
                        <div>
                            <el-row>
                                <el-col :span="20">
                                    <el-select v-model="selectedIdsList" multiple placeholder disabled class="select_view">
                                        <el-option disabled v-for="item in selectedList" :key="item.vehicleId" :label="item.vehicleNo" :value="item.vehicleId">
                                            <span v-text="item.name||''"></span>
                                            <span v-text="item.order||''"></span>
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="4" class="button_sites">
                                    <el-button type="primary" size="small" @click="siteSelect">设置</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form-item>
                    <!-- <el-form-item label="VIN:" prop="vin">
                        <el-input v-model="diaform.vin" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="自编号:" prop="vehicleId">
                        <el-input v-model="diaform.vehicleId"></el-input>
                    </el-form-item>
                    <el-form-item label="品牌:" prop="brand">
                        <el-input v-model="diaform.brand"></el-input>
                    </el-form-item>-->
                    <el-form-item label="备注:" prop="remark">
                        <el-input v-model="diaform.remark" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="!isCheck">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save('dialogForm')">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
        <linecar-select ref="siteSelectDialog" @updateSite="onSite"></linecar-select>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择线路组织" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
    import linecarSelect from '@/components/pages/admin/resourcesAllocation/lineCar/linecarSelect'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
  data() {
    return {
      type: '',
      isCheck: false,
      title: '',
      siteTypeList: [],
      updowmnDisabled: false,
      upDownList: [
        {
          value: 0,
          label: '上行'
        },
        {
          value: 1,
          label: '下行'
        }
      ],
      zdTypeList: [],
      props: {
        children: 'children',
        label: 'text',
        value: 'id',
        // multiple: true,
        checkStrictly: true
      },
      zzstatusList: [
        {
          label: '快速充电站',
          value: 1
        },
        {
          label: '一般充电站',
          value: 2
        }
      ],
      diaform: {
        routeId: '',
        routeName: '',
        orgId: '',
        vehicleId: '',
        vehicleNo: '',
        remark: '',
        vin: '',
        brand: '',
        ids: ''
      },
      rules: {
        routeName: [
          {
            required: true,
            message: '请选取线路',
            trigger: ['change']
          }
        ],
        ids: [
          {
            required: true,
            message: '请选取车辆',
            trigger: ['change']
          }
        ]
      },
      dialogVisible: false,
      formLabelWidth: '100px',
      selectedList: [],
      selectedIdsList: []
    }
  },
  components: {
    linecarSelect,
    institutionsTree
  },
  activated() {},
  created() {},
  watch: {
    type: function(val) {
      let t = ''
      let isCheck = false
      switch (val) {
        case 'add':
          t = '新增车辆分配'
          break
        case 'update':
          t = '修改车辆分配'
          break
        case 'view':
          t = '查看车辆分配'
          isCheck = true
          break
        default:
          t = '新增车辆分配'
      }
      this.isCheck = isCheck
      this.title = t
    },
    dialogVisible(newview) {
      if (!newview) {
        this.resetForm('dialogForm')
        this.diaform = {
          orgId: '',
          routeId: '',
          routeName: '',
          vehicleId: '',
          vehicleNo: '',
          remark: '',
          vin: '',
          brand: '',
          ids: ''
        }
        this.selectedIdsList = []
        this.selectedList = []
      }
    },
    selectedIdsList: function(val) {
      this.diaform.ids = val.map(one => one)
    }
  },
  methods: {
    // 组织
    selectInstitutions() {
      this.$refs.institutions.init()
    },
    addInstitutions(data) {
      console.log(data)
      this.diaform.routeId = data.id
      this.diaform.orgId = data.orgId
      this.diaform.routeName = data.text
    },
    init(option, type) {
      this.type = type ? type : 'add'
      this.dialogVisible = true
      if (option) {
        this.$nextTick(() => {
          if (this.type == 'add') {
            let addOption = JSON.parse(JSON.stringify(option))
            this.diaform.orgId = addOption.orgId
            this.diaform.routeId = addOption.routeId
            this.diaform.routeName = addOption.routeName
          } else {
            let updateOption = JSON.parse(JSON.stringify(option))
            this.diaform = updateOption
            if (updateOption.state == '启用') {
              this.diaform.state = 1
            } else if (updateOption.state == '禁用') {
              this.diaform.state = 0
            }
            this.selectedList = option.vehicles
            this.setSelectIdsList()
          }
        })
      }
    },
    setSelectIdsList(list) {
      list = list || this.selectedList
      this.selectedIdsList = list.map(one => {
        return one.vehicleId
      })
    },
    // 车辆选择
    siteSelect() {
      if (this.diaform.orgId && this.diaform.routeId) {
        this.$refs.siteSelectDialog.init(
          this.selectedIdsList,
          this.selectedList,
          this.diaform.orgId,
          this.diaform.routeId
        )
      } else {
        this.$message({
          type: 'error',
          message: '请先选择线路'
        })
      }
    },
    onSite(selectedIdsList, selectedList) {
      this.selectedList = selectedList
      this.selectedIdsList = selectedIdsList
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    save(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let url = '/routeRelVehicle/'
          if (this.type == 'add') {
            url = url + 'save'
          } else {
            url = url + 'modify'
          }
          let option = this.diaform
          this.$http.post(url, option).then(res => {
            this.$message({
              type: 'success',
              message: res.msg
            })
            this.dialogVisible = false
            this.$emit('updateList')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style type="text/css" lang="scss" scoped="line_car_add_update">
.select_view {
    width: 100%;
}

.button_sites {
    text-align: right;
}

</style>
